<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <Card v-if="!isEdit" title="账号信息" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">账号昵称</div>
                <Input class="width_300" v-model.trim="formData.nickName" :maxlength="15" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">账号</div>
                <Input class="width_300" v-model.trim="formData.userPhone" :maxlength="15" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">密码</div>
                <Input class="width_300" type="password" v-model.trim="formData.passWord" password></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">角色选择</div>
                <Select class="width_300" v-model="formData.groupId">
                    <Option v-for="info in roleList" :key="info.id" :value="info.id">{{ info.groupname }}</Option>
                </Select>
            </div>
        </Card>

        <Card title="基本信息" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">服务商名称</div>
                <Input class="width_300" v-model.trim="formData.name" :maxlength="15" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">公司地址</div>
                <city v-model="address_code"></city>
            </div>
            <div class="form_item">
                <div class="form_label">详细地址</div>
                <Input class="width_300" v-model.trim="address_note" :maxlength="50" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">联系人</div>
                <Input class="width_300" v-model.trim="formData.userName" :maxlength="15" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">手机号</div>
                <Input class="width_300" v-model.trim="formData.userTel" :maxlength="15" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">账号状态</div>
                <RadioGroup v-model="formData.status" type="button" button-style="solid">
                    <Radio label="1">开启</Radio>
                    <Radio label="0">关闭</Radio>
                </RadioGroup>
            </div>
        </Card>

        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirm">保存</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqGroupList } from '@/lib/request/auth';
import city from '@/components/city';
import { reqMerchantCreateAccount, reqMerchantSaveAccount } from '@/lib/request/auth2';
import { getCityTitles } from '@/lib/city.data';

export default {
    name: 'serviceProviderAdd',
    components: {
        city,
    },
    data() {
        return {
            title: null,
            formData: {
                id: null,
                nickName: null,
                userPhone: null,
                passWord: null,
                groupId: null,
                name: null,
                userName: null,
                userTel: null,
                status: '1',
            },
            address_code: [],
            address_note: null,
            roleList: [],
            isEdit: false,
        };
    },
    mounted() {
        this.routeParamsInjectFormData(['address_code', 'address_note']);
        this.title = this.isEdit ? '编辑服务商' : '添加服务商';
        this.getRoleList();
        this.getDetail();
    },
    watch: {
        //
    },
    methods: {
        getRoleList() {
            this.ajaxLoading = true;
            reqGroupList({
                groupId: 6,
            })
                .then(res => {
                    let list = res?.data?.list || [];
                    this.roleList = list;
                })
                .finally(res => {
                    this.ajaxLoading = false;
                });
        },
        //获取详情
        getDetail() {
            if (this.isEdit) {
                // this.showAjaxLoading();
                // reqBannerView({ id: this.formData.id })
                //     .then(res => {
                //         this.dataInjectFormData(res);
                //         // this.arrPic = this.formData.pic.split(this.picSeq);
                //     })
                //     .finally(() => {
                //         this.hideAjaxLoading();
                //     });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirm() {
            this.isEdit ? this.onConfirmEdit() : this.onConfirmSave();
        },
        onConfirmSave() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { nickName, userPhone, passWord, groupId, name, userName, userTel } = params || {};
            let vs = [
                { value: nickName, tip: '请输入账号昵称' },
                { value: userPhone, tip: '请输入账号' },
                { value: passWord, tip: '请输入密码' },
                { value: groupId, tip: '请选择角色' },
                { value: name, tip: '请输入商务商名称' },
                { value: userName, tip: '请输入联系人' },
                { value: userTel, tip: '请输入手机号' },
            ];

            let address_code = this.address_code;

            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    let address_title = getCityTitles(address_code);
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    reqMerchantCreateAccount(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { name, userName, userTel } = params || {};
            let vs = [
                { value: name, tip: '请输入商务商名称' },
                { value: userName, tip: '请输入联系人' },
                { value: userTel, tip: '请输入手机号' },
            ];

            let address_code = this.address_code;

            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    let address_title = getCityTitles(address_code);
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    reqMerchantSaveAccount(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped></style>
